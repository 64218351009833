/**
 * @module router
 * @summary This module contains the PrivateRoute & PublicRoute component
 */
import React, { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthConsumer } from '@zeliot/common/auth'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks' // Assuming you're using Apollo Client
import Loader from '../ui/Loader'

const GET_USER_CONFIGURATION = gql`
  query ($loginId: Int!) {
    userConfiguration(loginId: $loginId) {
      plan
    }
  }
`

/**
 * @summary A route component that allows only authenticated users
 * @param {object} props React component props
 */
export const PrivateRoute = ({ children, ...props }) => {
  return (
    <AuthConsumer>
      {({ authStatus }) => {
        if (!authStatus) {
          return <Redirect to={{ pathname: '/' }} />
        }
        return <Route {...props}>{children}</Route>
      }}
    </AuthConsumer>
  )
}

/**
 * @summary A route component that allows only unauthenticated users
 * @param {object} props React component props
 */
export const PublicRoute = ({ children, ...props }) => {
  let loginId = parseInt(localStorage.getItem('loginId'))

  const { data, loading, error } = useQuery(GET_USER_CONFIGURATION, {
    variables: { loginId },
    skip: !loginId, // Skip the query if loginId is falsy
  })

  let pathName
  if (loading) {
    return <Loader />
  } else if (error) {
    pathName = '/home/dashboard'
  } else {
    // Use the data from the query here
    console.log(loginId, 'loggg')
    // console.log(data?.userConfiguration?.plan, 'plan')
    if (data?.userConfiguration?.plan) {
      let plan_data = data.userConfiguration.plan
      // console.log(plan_data["features"],"arrayF")
      let featuresArray = plan_data['features']
      // Initialize an empty array to store key values
      const keyValues = []
      // Iterate over the array of objects using a for loop
      for (let i = 0; i < featuresArray.length; i++) {
        // Push the value of 'key' property into the keyValues array
        keyValues.push(featuresArray[i].key)
      }
      console.log(keyValues, 'keeeyyyysss')
      const includesFence = keyValues.some((value) =>
        value.includes('DASHBOARD_FENCE')
      )
      if (includesFence) {
        pathName = '/home/dashboardFence'
      } else {
        pathName = '/home/dashboard'
      }
    } else {
      pathName = '/home/dashboard'
    }
  }

  return (
    <AuthConsumer>
      {({ authStatus }) => {
        if (authStatus) {
          return <Redirect to={{ pathname: pathName }} />
        }
        return <Route {...props}>{children}</Route>
      }}
    </AuthConsumer>
  )
}
